(function () {
    'use strict';

    // header nav slides in
    if(document.querySelector('.js-header__nav-toggle')) {
        const headerToggle = document.querySelector('.js-header__nav-toggle');

        headerToggle.addEventListener('click', () => {
            document.querySelector('.js-header').classList.toggle('enabled');
            document.querySelector('.js-header__con').classList.toggle('enabled');
            document.querySelector('.js-header__overlay').classList.toggle('enabled');
        });
    }

    // Toggle header Nav
    if(document.querySelector('.js-header__nav-toggle')) {
        const headerToggle = document.querySelector('.js-header__nav-toggle');
    
        headerToggle.addEventListener('click', () => {
            headerToggle.classList.toggle('enabled');
            document.body.classList.toggle('disable-scroll');
        });
    }

    // Hide header when window scrolls down
    if(!(document.querySelector('.vertical-written.single-booklet'))) {
        let prevScrollPos = window.pageYOffset;
        window.addEventListener('scroll', () => {
            if(document.querySelector('.js-header')) {
                let currentScrollPos = window.pageYOffset;
                let header = document.querySelector('.js-header')
                let headerHeight = header.offsetHeight;

                if((currentScrollPos) > 0 && (!(document.querySelector('.single-booklet')))) {
                    header.classList.remove('transparent');
                } else {
                    header.classList.add('transparent');
                }

                if(prevScrollPos > currentScrollPos || currentScrollPos <= 80) {
                    header.style.top = '0';
                } else {
                    header.style.top = `-${headerHeight}px`;
                }
                prevScrollPos = currentScrollPos;
            }
        });
    }
})();