(function() {
    'use strict';

    // On Booklet Page
    if(document.querySelector('.vertical-written.single-booklet')) {

        const adjustContentHeight = () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
            window.addEventListener('resize', () => {
                let vh = window.innerHeight * 0.01;
                document.documentElement.style.setProperty('--vh', `${vh}px`);
            });
        }

        let target = document.querySelector('.js-box-outer');
        let targetWidth = target.scrollLeft;
        const scrollIndicator = document.querySelector('.indicator');
        const trigger = document.querySelector('.indicator-trigger');
		let viewportWidth = target.offsetWidth;
		const edgeOfIntro = trigger.offsetWidth;
        const contentLength = parseInt(targetWidth + edgeOfIntro);
        const content = document.querySelector(".js-box-inner");
        const contentWidth = content.scrollWidth;
        const drawerWidth = document.querySelector(".js-header__con").offsetWidth;
        const navHeight = document.querySelector(".js-header").offsetHeight;
        const scrollAmount = (contentWidth - viewportWidth) + drawerWidth - navHeight;
        // Debug
        // console.log(
        //     "drawerWidth", drawerWidth, 
        //     "contentWidth", contentWidth, 
        //     "scrollAmount", scrollAmount, 
        //     "viewportWidth", viewportWidth, 
        // );

        //////// Enable Horizontal Scroll
        const enableHorizontalScroll = () => {
            // Calculate scroll position when loaded and while detecting mouse move
            const calcScrollPos = () => {
                content.style.width = scrollAmount + "px";
                content.style.height = window.innerHeight + "px";
                content.style.transform = "translate3d(" + scrollAmount + "px, 0, 0);";
                content.setAttribute("data-" + scrollAmount, "transform: translate3d(" + (scrollAmount - ( Math.floor(window.innerHeight / 3.5) ) + 32) + "px, 0, 0)");
            }

            // skrollrCont
            const skrollrCont = () => {
                skrollr.init();
                document.querySelector("body").style.height = Math.floor((contentWidth / window.Height)) + "px";
                // document.querySelector("body").style.height = Math.floor((contentWidth / window.innderHeight)) + "px";
                document.querySelector(".js-box-outer").style.overflowX = "hidden";
            }

            calcScrollPos();
            skrollrCont();
        }

        //////// detect user agent
        const detectUA = () => {
            const ua = navigator.userAgent;
            const isIOS = /iP(hone|(o|a)d)/.test(ua);
            const isAndroid = /android/i.test(ua);
            if(isIOS || isAndroid) {
                console.log("This is mobile - ", ua);
                adjustContentHeight();
            } else {
                console.log("This is Desktop - ", ua);
                enableHorizontalScroll();
            }
        }

        // Identify ua and enable logic along with ua
        detectUA();

        //////// Control TOC
        if(document.querySelector('#ez-toc-container')) {
            const enableTOC = () =>  {
                let tocContainer = document.querySelector('#ez-toc-container');
                let tocTitle = document.querySelector('.ez-toc-title');
                let tocList = document.querySelector('.ez-toc-list');
                let elem = document.createElement('div');
                tocContainer.appendChild(elem);
                elem.classList.add('ez-toc-container_inner');
                elem.prepend(tocTitle);
                elem.append(tocList);

                if(!(tocContainer.classList.contains('contracted'))) {
                    tocContainer.classList.add('contracted');
                }
            }

            const controlTOCMobile = () => {
                viewportWidth = -Math.abs(viewportWidth)
                target.addEventListener('scroll', () => {
                    const toggler = document.querySelector(".ez-toc-toggle");
                    let tocContainer = document.querySelector('#ez-toc-container');
                    tocContainer.append(toggler);
                    // Debug
                    // console.log(
                    //     "viewportWidth", viewportWidth, 
                    //     "target.scrollLeft", target.scrollLeft
                    // );

                    if(target.scrollLeft <= viewportWidth) {
                        toggler.querySelector('a').innerHTML = '目次を表示';
                        toggler.classList.add('enabled');
                    } else {
                        toggler.classList.remove('enabled');
                    }
                });
    
                // if(scrollIndicator) {
                //     if(target.scrollLeft <= (contentLength - viewportWidth)) {
                //         scrollIndicator.style.opacity = '0';
                //     }
                // }
            }

            const getTranslate3d = (el) => {
                let values = el.style.transform.split(/\w+\(|\);?/);
                if(!values[1] || !values[1].length) {
                    return [];
                }
                return values[1].split(/,\s?/g);
            }

            const controlTOCDesktop = () => {
                window.onload = () => {
                    target = document.body;
                    viewportWidth = Math.abs(viewportWidth)
                    // console.log('target', target.offsetHeight);

                    target.addEventListener('mousewheel', () => {
                        const toggler = document.querySelector(".ez-toc-toggle");
                        let tocContainer = document.querySelector('#ez-toc-container');
                        tocContainer.append(toggler);
                        let posX = getTranslate3d(content);
                        posX = parseInt(posX[0].replace("px", ""));

                        // Debug
                        // console.log("viewportWidth", viewportWidth, "target.scrollLeft", target.scrollTop)
                        // console.log("contentWidth", contentWidth);
                        // console.log("content.style.transform", content.style.transform);
                        // console.log("posX", posX);
                        
                        if(posX <= viewportWidth) {
                            toggler.classList.remove('enabled');
                            tocContainer.classList.add('disabled');
                        } else {
                            toggler.querySelector('a').innerHTML = '目次を表示';
                            tocContainer.classList.remove('disabled');
                            toggler.classList.add('enabled');
                            tocContainer.style.right = posX + "px";
                        }
                    })
                }
            }

            // enableTOC();
            // controlTOCMobile();
            // controlTOCDesktop();

            var clickToCloseToc = function() {
                $('ul.ez-toc-list').hide();
                $('#ez-toc-container').addClass('contracted').shrinkTOCWidth();
                $('.ez-toc-toggle > a').innerHTML = '目次';
            }

            // Close TOC when the link clicked
            if(target.querySelector('.ez-toc-list')) {
                let tocList = target.querySelector('.ez-toc-list')
                let tocLink = tocList.querySelectorAll('a');

                tocLink.forEach((elem) => {
                    elem.addEventListener('click', clickToCloseToc);
                });
            }
        }

    } else {
        const windowHeight = window.innerHeight;
        const bodyHeight = document.body.scrollHeight;
        const footerHeight = document.querySelector('.footer').offsetHeight;
        const socialHeight = document.querySelector('.social').offsetHeight;
        const dokusyokaiHeight = document.querySelector('.dokusyokai').offsetHeight;

        const bottomElemHeight = footerHeight + socialHeight + dokusyokaiHeight;
        // Debug
        // console.log(
        //     "bodyHeight: ", bodyHeight,
        //     "bottomElemHeight: ", bottomElemHeight,
        //     "windowHeight: ", windowHeight,
        // );
        if(document.querySelector('#ez-toc-container')) {
            window.addEventListener('scroll', () => {
                // console.log(document.documentElement.scrollTop);
                if((document.documentElement.scrollTop <= 360) || 
                    (document.documentElement.scrollTop) >= (bodyHeight - (bottomElemHeight + windowHeight))
                ) {
                    document.querySelector('#ez-toc-container').classList.remove('enabled');
                } else {
                    document.querySelector('#ez-toc-container').classList.add('enabled');
                }
            })
        }
    }
})();
