(function() {
    'use strict';
    
    const swiperTop = new Swiper('.top .swiper-container', {
        loop: true,
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        autoplay: {
            delay: 5000
        },
        pagination: {
            el: ".swiper-pagination",
        },
    });
})();